#about {
    background: #f7f7f7;
}
#about-header {
    padding-top: 1em;
}
.container {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 10px;
    width: 100%;
}
#headshot {
    width: 250px;
    margin: 85px 0 15px 0;
    border-top-left-radius: 45%;
    border-bottom-right-radius: 45%;
}
.headshot-container ul {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    padding-inline-start: 60px;
}
#hero-description {
    width: 90%;
}
#about-icon-container {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-around;
}
.about-icon {
    width: 150px;
    height: 50px;
    font-size: 50px;
    margin: 25px 15px 10px 15px;
    text-align: center;
}
.about-icon p {
    font-size: 12px;
    margin: 10px;
}
.about-icon-header {
    font-family: Raleway, sans-serif;
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
}
i {
    color: #05C2C9;
}
#background-description {
    width: 85%;
    text-align: left;
}
#background-description a {
    color: #ff3605;
    text-decoration: none;
}
#background-description a:hover {
    color: #2e31fd;
}
#tech-text {
    margin: 20px 0 0 0;
    width: 85%;
    text-align: left;
}
.about-skills-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    overflow: hidden;
    padding: 0;
    margin: 20px 0 0 20px;
    list-style: none;
    text-align: left;
}
.about-skills-item {
    position: relative;
    margin-bottom: 10px;
    padding-left: 20px;
}
.about-skills-item::before {
    content: "▹";
    position: absolute;
    left: 0;
    color: #54c0de;
    font-size: 14px;
    line-height: 12px;
}

@media (min-width: 767px) and (max-width: 1023px) {
    .about-icon p {
        font-size: 14px;
    }
    .about-icon {
        width: 200px;
    }

    #tech-text {
        margin: 20px 0 0 0;
        width: 90%;
        text-align: center;
    }
}

@media (min-width: 1024px) {
    .headshot-container {
        width: 85vw;
    }
    #hero-description {
        width: 50%;
        padding-bottom: 2em;
    }
    #about-icon-container{
        padding-top: 1em;
    }
    .about-icon p {
        font-size: 14px;
    }
    .about-icon {
        width: 300px;
        font-size: 5em;
    }
    #headshot {
        width: 400px;
        padding: 2em 0 0 3em;
    }
    #about-content {
        display: flex;
        flex-direction: row;
        width: 85%;
        padding: 2em 0 5em 0;
        justify-content: space-around;
        margin: 0 2em;
    }
    #about-content-text {
        width: 50%;
    }
    #background-description {
        width: 100%;
        text-align: left;
        padding-top: 6em;
        margin-right: -5em;
    }
    #tech-text {
        width: 100%;
        text-align: center;
    }
}