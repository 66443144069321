.images {
  //image container
  width: 100%;
  height: 175px;
  position: relative;
  overflow: hidden;
  background: transparent;

  @media (min-width: 767px) and (max-width: 1023px) {
    height: 350px;
  }

  @media (min-width: 1024px) {
    width: 50%;
    max-width: 600px;
    height: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 1s, transform 1s;

    &.show-image {
      transform: translateX(0);
      visibility: visible;
      opacity: 1;
      transition: opacity 1s, transform 1s;
    }
  }

  button {
    cursor: pointer;
  }

  .slider-dots {
    position: absolute;
    width: 100%;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);

    button {
      //dots
      width: 15px;
      height: 15px;
      border-radius: 50%;
      padding: 0;
      background: #54c0de;
      border: solid #54c0de 2px;

      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }
  }

  .button {
    width: 70px;
    height: 70px;
    background: #54c0de;
    border: none;
    position: absolute;

    &.prev-button {
      left: 0;
      bottom: 50%;
      transform: translateY(50%);
    }

    &.next-button {
      right: 0;
      bottom: 50%;
      transform: translateY(50%);
    }

    &.show {
      visibility: visible;
    }
  }
}
