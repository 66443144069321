.App {
  text-align: center;
}

section {
  color: #616161;
  padding: 3em 0;
  position: relative;
}
.flex {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.header {
  color: #444649;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  font-size: 30px;
  position: relative;
}
.header-bar {
  border-top: solid #444649 4px;
  width: 100px;
  margin: 10px auto;
  padding-bottom: 50px;
}
.general-text {
  font-family: Raleway, sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  line-height: 1.3;
}
